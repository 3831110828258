@import '/src/styles/flex.scss';
@import '/src/styles/margin.scss';
@import '/src/styles/padding.scss';
@import '/src/styles/common.scss';

$black: #333;
$white: #fff;
$dark: #555;
$light: #f2f2f2;

body {
  margin: 0;
  background-color: $light;
  font-family: "Lato";
  font-weight: 300;
  font-size: 16px;
  color: $dark;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Raleway";
  font-weight: 300;
  color: $black;
}

ul {
  list-style-type: none;
}
