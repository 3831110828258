
.pointer {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

.h-100 {
  height: 100%;
}

.overflow-auto {
  overflow: auto;
}

.text-align-center {
  text-align: center;
}

.text-align-left {
  text-align: left;
}

.italic {
  font-style: italic;
}